/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-27 17:17:58
 * @Module: 印章管理
 */
 <template>
  <div class="sealManagement">
    <el-card shadow="never" :body-style="{position:'relative'}">
      <div slot="header">
        <hdq-section title="印章管理" moreText="" />
      </div>
      <authorized :hasAuth.sync="hasAuth" />
      <!-- <el-alert v-show="!hasAuth" style="margin-bottom:10px;" @click.native="postCompanyAuthorize" title="您的公司授权已过期，此页面功能将限制使用，请法人点击重新授权。" :closable="false" type="error">
      </el-alert> -->
      <el-table :data="list" style="width: 100%">
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column label="印章名称">
          <template slot-scope="scope">
            {{scope.row.sealName}}
          </template>
        </el-table-column>
        <el-table-column label="印章样式">
          <template slot-scope="scope">
            <el-avatar :size="60" style="background:transparent" :src="$oss+scope.row.sealImageUrl"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="授权数量">
          <template slot-scope="scope">
            {{scope.row.authCount}}人
          </template>
        </el-table-column>
        <el-table-column label="操作" :resizable="false">
          <template slot-scope="scope">
            <el-link :disabled="!hasAuth" :underline="false" type="primary" @click="()=>operate('add',scope)">新增授权</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link :disabled="!hasAuth" :underline="false" type="primary" @click="()=>operate('more',scope)">查看授权</el-link>
            <!-- <el-divider direction="vertical"></el-divider>
            <el-link :underline="false" type="info" @click="()=>operate('delete',scope)">删除</el-link> -->
          </template>
        </el-table-column>
      </el-table>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </el-card>
    <el-dialog @close="close" destroy-on-close title="新增授权" :visible.sync="dialogVisible" width="30%">
      <el-form :rules="rules" label-position="top" size="small" :model="form" ref="form">
        <div class="label">授权对象<el-link type="warning" v-show="isCandidate">（选择的授权对象为授权，请他登陆账号完成授权）</el-link>
        </div>
        <el-form-item prop="empUuid">
          <el-select v-model="form.empUuid" style="width:100%;" placeholder="请选择授权对象">
            <el-option :label="item.name" :value="item.uuid" v-for="item in accountNormalList" :key="item.uuid"></el-option>
          </el-select>
        </el-form-item>
        <!-- <div class="label">授权角色</div>
        <el-form-item prop="sealRole">
          <el-select v-model="form.sealRole" style="width:100%;" placeholder="请选择授权角色">
            <el-option label="印章-审批员" value="SEAL_EXAMINER"></el-option>
            <el-option label="印章-使用员" value="SEAL_USER"></el-option>
          </el-select>
        </el-form-item> -->
        <div class="label">有效期</div>
        <el-form-item prop="expireTime">
          <el-date-picker style="width:100%;" value-format="timestamp" v-model="form.expireTime" align="right" type="date" placeholder="请选择有效期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :disabled="disabled">提交</el-button>
      </span>
    </el-dialog>
    <qrDialog ref="qrDialog" @close="qrDialogClose">
      <template>
        <span class="text">
          <span>请法人用</span>
          <span>支付宝APP</span>
          <span>扫码并按照提示完成签署</span>
        </span>
      </template>
    </qrDialog>
  </div>
</template>
 <script>
import { mapState } from "vuex";
import hdqPagination from "@/components/hdqPagination.vue"
import authorized from "@/components/authorized.vue"
import qrDialog from "@/components/qrDialog.vue"
let js;
export default {
  components: {
    hdqPagination,
    authorized,
    qrDialog
  },
  data () {
    return {
      list: [],
      dialogVisible: false,
      hasAuth: true,  // 公司授权是否可用
      pickerOptions: {
        shortcuts: [{
          text: '一个月',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 31);
            picker.$emit('pick', date);
          }
        }, {
          text: '三个月',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 31 * 3);
            picker.$emit('pick', date);
          }
        }, {
          text: '一年',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
            picker.$emit('pick', date);
          }
        }, {
          text: '三年',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 365 * 3);
            picker.$emit('pick', date);
          }
        }]
      },
      rules: {
        empUuid: [
          { required: true, message: '请选择授权对象', trigger: ['blur', 'change'] }
        ],
        // sealRole: [
        //   { required: true, message: '请选择授权角色', trigger: ['blur', 'change'] }
        // ],
        expireTime: [
          { required: true, message: '请选择有效期', trigger: ['blur', 'change'] }
        ],
      },
      form: {
        empUuid: '',
        // sealRole: '',
        expireTime: ''
      },
      sealId: '',
      accountNormalList: []   //  员工列表
    };
  },
  computed: {
    isCandidate () {
      if (this.accountNormalList.length) {
        if (this.form.empUuid) {
          const index = this.accountNormalList.findIndex(item => item.uuid == this.form.empUuid);
          return this.accountNormalList[index].isCandidate ? false : true;
        } else {
          return false
        }
      } else {
        return false
      }
    },
    disabled () {
      if (this.isCandidate) {
        return true
      } else {
        return this.$utils.test.empty(this.form.empUuid) || this.$utils.test.empty(this.form.expireTime)
      }
    },
    ...mapState({
      user: state => state.user.user,
    })
  },
  mounted () {
    this.getSealList();
    this.getAccountNormalList();
  },
  methods: {
    // 关闭前回调
    close () {
      this.form = {
        empUuid: '',
        // sealRole: '',
        expireTime: ''
      }
    },
    async getSealList (current = 1, size = 10) {
      const { status, data } = await this.$api.getSealList({ current, size })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    paginationChange ({ current, size }) {
      this.getSealList(current, size)
    },
    operate (type, data) {
      if (type == 'add') {
        this.sealId = data.row.sealId
        this.dialogVisible = true;
      } else if (type == 'more') {
        this.$router.push({ path: `/enterprise/SealManagementDetail/${data.row.sealId}?title=${data.row.sealName}` })
      } else if (type == 'delete') {
        console.log(data)
      }

    },
    postSealAuth () {
      const { sealId } = this
      return this.$api.postSealAuth({ ...this.form, sealId })
    },
    async getAccountNormalList () {
      const { data, status } = await this.$api.getAccountNormalList()
      if (status === 200) {
        this.accountNormalList = data
      }
    },
    submitForm () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { status, info, data } = await this.postSealAuth()
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            })
            const index = this.list.findIndex(item => item.sealId == this.sealId)
            this.list[index].authCount++;
            this.dialogVisible = false;
            this.$refs.qrDialog.show(data.authorizationSignUrl);
            js = setInterval(async () => {
              const { data: sealData, status: sealStatus } = await this.$api.getSealAuth({ authUuid: data.uuid });
              if (sealStatus == 200 && (sealData.authorizeStatus !== null)) {
                this.$refs.qrDialog.hidden();
                clearInterval(js);
              }
            }, 1000)
          } else {
            this.$message({
              type: 'error',
              message: info
            })
          }
        }
      })
    },
    // async getCompanyAuthStatus () {
    //   const { status, data } = await this.$api.getCompanyAuthStatus({ companyUuid: this.user.companyUuid });
    //   if (status === 200) {
    //     this.hasAuth = data.hasAuth;
    //   }
    // },
    // // 公司授权
    // async postCompanyAuthorize () {
    //   const { data } = await this.$api.postCompanyAuthorize();
    //   const { authUrl, taskUuid } = data;
    //   this.$refs.qrDialog.show(authUrl);
    //   js = setInterval(async () => {
    //     const { data, status } = await this.$api.getCompanyAuthTask({ taskUuid });
    //     if (status == 200 && data.success) {
    //       this.$refs.qrDialog.hidden();
    //       clearInterval(js);
    //     }
    //   }, 1000)
    // },
    qrDialogClose () {
      js && clearInterval(js);
    }
  },
};
 </script>
 <style lang='scss' scoped>
.sealManagement {
  .label {
    // line-height: 32px;
    margin-bottom: 6px;
    font-size: 14px;
    color: #666666;
  }
}
</style>